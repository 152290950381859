import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { BaseStateActions } from '../state/base/base.actions';
import { BaseSelectors } from '../state/base/base.selectors';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private static redirecting = false;
    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private store: Store,
    ) {}

    public switchLanguage(newLanguage: string): void {
        const currentLocation = window.location.href;
        let newLocation = '';
        if (currentLocation.endsWith(`/${this.locale}`)) {
            newLocation = currentLocation.replace(`/${this.locale}`, `/${newLanguage}/`);
        } else {
            newLocation = currentLocation.replace(`/${this.locale}/`, `/${newLanguage}/`);
        }
        this.store.dispatch([new BaseStateActions.Common.SetLanguage(newLanguage)]);
        window.location.href = newLocation;
    }

    public validateOrRedirectRoute(): void {
        const languageInStore = this.store.selectSnapshot(BaseSelectors.getLanguage);
        if (languageInStore && languageInStore !== this.locale) {
            const supportedLanguages = environment.languageSettings.languages.map(language => language.value);
            const languageMatch = supportedLanguages?.find(language => language === languageInStore);
            if (languageMatch && !LanguageService.redirecting) {
                LanguageService.redirecting = true;
                this.switchLanguage(languageInStore);
            }
        }
    }

    public initLocale() {
        // Should date format change together with language?
        // Language and regional settings should be separated but in that case, month names will be wrong.
        // en-SI; moment.format('MMMM') would result in 'februar'
        moment.locale(this.locale);
        // moment.locale('sl');
    }
}
